.cdx-ai {
  background-color: #f0f0f0;
  padding: 6px;
  padding-right: 28px;
  border-radius: 4px;
  background-image: url('../../../public/icons/auto-awesome.svg');
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: calc(100% - 4px) center;
  font-family: monospace;
  font-size: 14px;
  display: inline;
}

.cdx-ai:hover {
  background-color: #e9e9e9;
}

.cdx-inline-checkbox {
  font-size: 14px;
  cursor: pointer;
  height: 16px;
  width: 16px;
  padding: 0px;
  border: 1px solid #ccc;
  position: relative;
  top: 2px;
}
